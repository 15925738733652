export const enum CONFIG_ANIM_TIME {
  SHORT = 200,
  MEDIUM = 400,
  LONG = 500,
  ONE_SECOND = 1000,
  TWO_SECONDS = 2000,
  THREE_SECONDS = 3000,
  HALF_MINUTE = 30000,
}

export const HOME_PAGE_DYNAMIC_CONTENT_KEY = {
  MENDEN: 'menden',
  HAMM: 'hamm',
  ISERLOHN: 'iserlohn',
};

export const scriptSchemaHamm = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: 'Was kostet ein Immobilienmakler in Hamm?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Die Maklerprovision ist in Deutschland gesetzlich geregelt und wird in der Regel zwischen Käufer und Verkäufer aufgeteilt. Die Provision kann je nach individueller Vereinbarung variieren. Bei einer Vermietung ist die Provision vom Vermieter zu tragen, da das Bestellerprinzip gilt. Die Kosten lohnen sich jedoch, da ein Makler den bestmöglichen Preis erzielt und den gesamten Prozess professionell begleitet.',
      },
    },
    {
      '@type': 'Question',
      name: 'Warum sollte ich einen Makler beauftragen?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Ein erfahrener Immobilienmakler übernimmt alle wichtigen Schritte im Verkaufs- oder Vermietungsprozess. Dazu gehören die marktgerechte Wertermittlung, eine professionelle Vermarktungsstrategie, die Organisation von Besichtigungen und die rechtssichere Vertragsabwicklung. Durch die Expertise eines Maklers erzielen Verkäufer oft einen höheren Verkaufspreis, vermeiden rechtliche Fallstricke und sparen wertvolle Zeit.',
      },
    },
    {
      '@type': 'Question',
      name: 'Wie lange dauert es, eine Immobilie in Hamm zu verkaufen?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Die Dauer eines Immobilienverkaufs hängt von verschiedenen Faktoren wie Lage, Preisgestaltung und Marktnachfrage ab. In begehrten Lagen kann ein Verkauf bereits innerhalb weniger Wochen abgeschlossen sein. Durch eine zielgerichtete Vermarktung, die Nutzung professioneller Netzwerke und eine realistische Preisbewertung kann ein Makler den Verkaufsprozess erheblich beschleunigen.',
      },
    },
  ],
};

export const scriptSchemaIserlohn = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: 'Wie läuft der Verkaufsprozess mit einem Immobilienmakler ab?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Ein Immobilienmakler begleitet Sie durch alle Phasen des Verkaufs. Zunächst wird eine professionelle Wertermittlung durchgeführt, gefolgt von der Vermarktung auf relevanten Plattformen. Nach der Koordination von Besichtigungsterminen übernimmt der Makler die Preisverhandlungen und begleitet Sie bis zum notariellen Vertragsabschluss. So stellen Sie sicher, dass Sie einen optimalen Verkaufspreis erzielen und der gesamte Prozess effizient und rechtssicher abläuft.',
      },
    },
    {
      '@type': 'Question',
      name: 'Welche Unterlagen werden für den Immobilienverkauf benötigt?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Für einen reibungslosen Verkauf sollten wichtige Dokumente bereitliegen, darunter der Grundbuchauszug, ein aktueller Energieausweis, Baupläne sowie Nachweise über Modernisierungen. Ein Immobilienmakler hilft Ihnen, alle erforderlichen Unterlagen zu beschaffen. Fehlende Dokumente können den Verkaufsprozess verzögern – mit professioneller Unterstützung sind Sie bestens vorbereitet.',
      },
    },
    {
      '@type': 'Question',
      name: 'Kann ich meine Immobilie auch ohne Makler verkaufen?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Ja, ein Verkauf ohne Makler ist möglich, jedoch oft mit mehr Aufwand und Risiken verbunden. Ein Makler übernimmt nicht nur die Wertermittlung und Vermarktung, sondern sorgt auch für rechtssichere Verträge und einen reibungslosen Ablauf, um Fehler und finanzielle Einbußen zu vermeiden. Gerade in einem dynamischen Markt kann eine falsche Preisstrategie zu längeren Verkaufszeiten oder einem niedrigeren Erlös führen.',
      },
    },
    {
      '@type': 'Question',
      name: 'Wann ist der beste Zeitpunkt, eine Immobilie in Iserlohn zu verkaufen?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Der ideale Verkaufszeitpunkt hängt von Marktentwicklung, Nachfrage und Zinssituation ab. Ein Immobilienmakler kann Sie beraten, wann Ihre Immobilie den besten Preis erzielt und ob sich ein Verkauf aktuell lohnt. Markttrends ändern sich regelmäßig – eine professionelle Einschätzung hilft, den richtigen Moment für den Verkauf nicht zu verpassen.',
      },
    },
  ],
};
