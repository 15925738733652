const de = {
  translation: {
    imprint: 'Impressum',
    dataProtection: 'Datenschutz',
    aboutUsSmall: 'über uns',
    aboutUs: 'über uns',
    aboutUsTitle: 'about us',
    weAreBeckschulte: 'Wir sind <span class="text-primary-500">Beckschulte<span>',
    weAreBeckschulteMenden: 'Über uns – Beckschulte Immobilien-Management',
    weAreBeckschulteHamm: 'Über uns – Beckschulte Immobilien-Management',
    weAreBeckschulteIserlohn: 'Über uns – Beckschulte Immobilien-Management',
    aboutUsDescriptionFirst:
      'Wir sind ein Team von Spezialisten und befassen uns seit mehr als 25 Jahren erfolgreich mit der Verwaltung, der Vermietung und dem Verkauf von Wohn- u. Gewerbe-Immobilien im Kreis Unna und dem gesamten Ruhrgebiet. Seriosität, Kompetenz und Engagement sind die Grundlagen unseres Handelns. Besonderen Wert legen wir auf die Qualifikation unserer Mitarbeiter, die über ausgezeichnete, fachspezifische Kenntnisse und Berufserfahrung verfügen. So erwartet Sie bei uns Professionalität verbunden mit Persönlichkeit.',
    aboutUsMendenDescription:
      'Seit über 25 Jahren ist <b>Beckschulte Immobilien-Management</b> Ihr zuverlässiger Partner für den <b>Verkauf, die Vermietung und Verwaltung</b> von Immobilien. Als Immobilienmakler stehen wir Ihnen in Menden und Umgebung gerne zur Verfügung. Mit tiefgehender <b>Marktkenntnis, persönlicher Betreuung</b> und maßgeschneiderten Lösungen begleiten wir Sie professionell bei jedem Schritt. Unser erfahrenes Team steht für <b>Seriosität, Transparenz und maximale Kundenzufriedenheit</b>. Vertrauen Sie auf unsere Expertise – <b>wir machen Immobiliengeschäfte einfach und erfolgreich!</b>',
    aboutUsHammDescription:
      'Seit <b>über 25 Jahren</b> ist Beckschulte Immobilien-Management Ihr vertrauenswürdiger Partner für den <b>Verkauf, die Vermietung und Verwaltung</b> von Immobilien in Hamm und Umgebung. Dank unserer <b>umfassenden Marktkenntnis</b> und individuellen Betreuung begleiten wir Sie professionell durch den gesamten Prozess. Unser engagiertes Team steht für <b>Zuverlässigkeit, Transparenz und erfolgreiche Immobiliengeschäfte</b>. Vertrauen Sie auf unsere Expertise – wir machen den Immobilienverkauf einfach und sicher!',
    aboutUsIserlohnDescription:
      'Seit <b>über 25 Jahren</b> ist Beckschulte Immobilien-Management Ihr vertrauenswürdiger Partner für den <b>Verkauf, die Vermietung und Verwaltung</b> von Immobilien in Iserlohn und Umgebung. Wir setzen auf <b>fachkundige Beratung, fundierte Marktanalysen</b> und eine <b>maßgeschneiderte Vermarktung</b>, um für unsere Kunden den bestmöglichen Erfolg zu erzielen. Unser Team steht für <b>Transparenz, Professionalität und persönliche Betreuung</b> – damit Ihr Immobiliengeschäft sicher und erfolgreich abläuft.',
    aboutUsTeamDescription: 'freundlich – hilfsbereit – kompetent',
    readOurNews: 'Read our News',
    readOurNewsTitleFirst: 'What will happen to house prices in 2022?',
    readOurNewsTitleSecond: 'The 10 most in-demand rural locations for buyers',
    readOurNewsTitleThird: 'What can you buy in the happiest places to live?',
    readOurNewsDescriptionFirst:
      'Are you one of the many home-hunters looking to move this year? We’ve never seen the property market as hectic ...',
    readOurNewsDescriptionSecond:
      'Are you looking to make a life-changing move? If so, where will it be, and what type of home is at the top of your ...',
    readOurNewsDescriptionThird:
      'Our annual Happy at Home Index, now in its 10th year, has uncovered the happiest places to live in Germany ...',
    verwalten: 'Verwalten',
    verwaltenDescription:
      'Seit mehr als 25 Jahren vertrauen unsere Kunden auf unseren Sachverstand und legen die Betreuung ihrer Immobilien in unsere Hände. ',
    vermieten: 'Vermieten',
    rental: 'Vermieten',
    rentalDescription:
      'Mieter und Vermieter haben mit uns einen Partner an Ihrer Seite der Sie engagiert und kompetent betreut.',
    search: 'Suche',
    residentalObject: 'Wohn-Objekte',
    commercialObject: 'Gewerbe-Objekte',
    weOfferYouFollowingObject: 'Wir bieten Ihnen folgende Objekte an',
    page: 'Seite',
    object: 'Objekte',
    verkaufen: 'Verkaufen',
    verkaufenDescription:
      'Käufer und Verkäufer profitieren von unserer langjährigen Erfahrung und unserer professionellen Vorgehensweise.',
    sale: 'Verkaufen',
    saleDescription:
      'Mit uns haben Sie einen kompetenten Partner an Ihrer Seite, der den Verkauf Ihrer Immobilie professionell und engagiert betreibt.',
    certificateHeader: 'Sachverständiger für\nImmobilienbewertung',
    heroMotto: "...weil’s mit uns <br /> besser geht  <span class='text-primary-500'>.</span>",
    heroMottoMenden: 'Immobilienmakler in<br />Menden gesucht?',
    heroMottoHamm: 'Immobilienmakler in<br />Hamm gesucht?',
    heroMottoIserlohn:
      'Ihr Immobilienmakler in<br />Iserlohn – Professionelle<br />Unterstützung beim<br />Immobilienverkauf',
    contact: 'Kontakt',
    watchTheFilm: 'Film ansehen',
    propertyManagement: 'Immobilien - Verwaltung',
    propertyManagementDescriptionFirst:
      'Die Verwaltung von Immobilien ist eine anspruchsvolle Aufgabe. Sie erfordert Spezialkenntnisse aus unterschiedlichen Bereichen sowie das Zusammenwirken verschiedenster Fachleute. Seit mehr als 25 Jahren vertrauen unsere Kunden auf unseren Sachverstand und legen die Betreuung ihrer Immobilien und den Erhalt dieser Vermögenswerte in unsere Hände. Für unsere Kunden entwickeln wir individuelle Konzepte mit dem Ziel, die Rentabilität und Werthaltigkeit ihrer Immobilien zu sichern und zu verbessern. Fachleute aus der Wohnungswirtschaft, Betriebswirte und Juristen sind Garanten für unsere professionelle Verwaltung.',
    propertyManagementDescriptionSecond:
      'Seit mehr als 20 Jahren vertrauen unsere Kunden auf unseren Sachverstand und legen die Betreuung ihres Eigentums und den Erhalt ihrer Vermögenswerte in unsere Hände. Diese vertrauensvolle Zusammenarbeit ist Grundlage unseres Handelns.',
    propertyManagementDescriptionThird:
      'Fachleute aus der Grundstücks- u. Wohnungswirtschaft, Betriebswirte und juristische Berater sind der Garant für eine professionelle Verwaltung.',
    propertyManagementDescriptionFourth:
      'Für unsere Kunden entwickeln wir individuelle Konzepte mit dem Ziel, die Rentabilität und Werthaltigkeit Ihrer Immobilien zu sichern und zu verbessern. Dabei gehen wir auf spezielle Wünsche unserer Kunden ein und gestalten die Verwaltungstätigkeit übersichtlich und verständlich.',
    propertyManagementDescriptionFifth:
      'Im Folgenden finden Sie unseren Leistungsumfang aus den Bereichen der Miet- u. WEG-Verwaltung. Wenn Sie dazu weitergehende Fragen haben zögern Sie nicht uns anzusprechen.',
    wegAdmin: 'WEG - administration',
    wegAdminDescriptionFirst:
      'Die Verwaltung gemeinschaftlichen Eigentums ist eine anspruchsvolle Aufgabe. Sie erfordert Spezialkenntnisse in kaufmännischer, technischer, rechtlicher und organisatorischer Hinsicht.',
    wegAdminDescriptionSecond:
      'Dabei geht es um den sorgsamen Umgang mit den anvertrauten Vermögenswerten. Insbesondere geht es darum, die Attraktivität und Werthaltigkeit einer Immobilie zu erhalten und zu verbessern.',
    wegAdminDescriptionThird: 'Unser Leistungsumfang',
    wegAdminDescriptionFourth:
      'Wichtig ist die Fähigkeit, unterschiedliche Interessen im Sinne gemeinschaftlicher Ziele auszugleichen.',
    wegAdminDescriptionFifth:
      'Nicht zuletzt geht es darum, die Attraktivität einer Wohnanlage zu erhalten bzw. zu verbessern.',
    wegAdminService: 'Unsere Serviceleistungen für Sie:',
    wegAdminServiceListFirst: 'Erstellung der Hausgeldabrechnung und des Wirtschaftsplans',
    wegAdminServiceListSecond: 'Einberufung / Leitung / Protokollierung der Eigentümerversammlung',
    wegAdminServiceListThird: 'Umsetzung von Beschlüssen',
    wegAdminServiceListFourth: 'Ansprechpartner für Eigentümer und Dritte',
    wegAdminServiceListFifth: '24 Stunden Notdienst',
    wegAdminServiceListSixth: 'Prüfung und Optimierung von Verträgen',
    wegAdminServiceListSeventh:
      'Führung der Objekt- und Finanzbuchhaltung inkl. Mahn- und Klagewesen',
    wegAdminServiceListEight: 'Regelmäßige Begehung der Immobilie',
    wegAdminServiceListNine: 'Anweisung und Kontrolle von Servicekräften',
    wegAdminServiceListTen:
      'Vergabe und Überwachung von Reparatur-Instandhaltungs- und Modernisierungsmaßnahmen',
    wegAdminServiceListEleven: 'Planung von baulichen und gestalterischen Maßnahmen',
    wegAdminServiceListTwelve:
      'Strategische Planung mit dem Ziel, die Aufwands- und Ertragskomponenten zu optimieren',
    wegAdminServiceListThirteen:
      'Vergabe und Überwachung von Reparaturen und Instandhaltungsmaßnahmen',
    wegAdminServiceListFourteen: 'Planung von zukünftigen baulichen und gestalterischen Maßnahmen',
    wegAdminServiceListFifteen:
      'Strategische Planung mit dem Ziel, die Aufwands- und Ertragskomponenten zu optimieren',
    wegAdminServiceListSixteen: 'Wir übernehmen Verantwortung damit Sie entlastet werden.',
    rentalManagement: 'Miet - Verwaltung',
    rentalAdministrator: 'WEG - Verwaltung',
    rentalManagementDescription:
      'Auf Eigentümer von Mietobjekten kommen stetig steigende Anforderungen zu. Insbesondere komplexe rechtliche Anforderungen, die Planung und Begleitung von Instandhaltungs- und Modernisierungsmaßnahmen, die Suche nach solventen Mietern sowie das Kostenmanagement sind für Eigentümer von Mietobjekte eine Herausforderung.',
    rentalManagementService: 'Unser Leistungsumfang',
    objectData: 'Objektdaten',
    doYouWantToRent: 'Sie möchten <span class="text-primary-500">vermieten?</span>',
    rentDescription:
      'Die langfristige Sicherung der Mieterträge steht für die meisten Vermieter im Vordergrund. Deswegen ist es besonders wichtig, passende solvente Mieter zu finden, die mit der Mietsache pfleglich umgehen. Mit uns haben Sie einen kompetenten Partner an Ihrer Seite, der die Vermietung Ihrer Immobilie professionell und engagiert betreibt. Nutzen Sie unsere Erfahrung und sprechen Sie uns an.',
    rentServices: 'Unser Leistungsumfang',
    rentServiceForYouFirst: 'Besichtigung und Erfassung Ihrer Immobilie',
    rentServiceForYouSecond: 'Kompetente Beratung',
    rentServiceForYouThird: 'Erstellung einer ansprechenden Fotoserie',
    rentServiceForYouFourth: 'Gestaltung eines aussagekräftigen Exposés',
    rentServiceForYouFifth: 'Präsentation auf diversen Internetportalen',
    rentServiceForYouSixth: 'Einsatz von Vermietungsschildern',
    rentServiceForYouSeventh: 'Versierte Führung bei Besichtigungen',
    rentServiceForYouEight: 'Bonitätsprüfung der Mietinteressenten / Mieterselbstauskunft',
    rentServiceForYouNine: 'Erstellung aktueller Mietverträge',
    rentServiceForYouTen: 'Abschlussgespräch ',
    rentalManagementServiceListFirst: 'Abschluss und Kündigung von Mietverträgen',
    rentalManagementServiceListSecond: 'Mieterhöhungspotentiale aufdecken und durchsetzen',
    rentalManagementServiceListThird: 'Erstellung der Betriebskosten- und Heizkostenabrechnung',
    rentalManagementServiceListFourth: 'Bereitstellen von Unterlagen für Ihren Steuerberater',
    rentalManagementServiceListFifth:
      'Persönlicher Ansprechpartner für Eigentümer, Mieter und Dritte',
    rentalManagementServiceListSixth: '24 Stunden Notdienst',
    rentalManagementServiceListSeventh: 'Information und Kommunikation über wesentliche Ereignisse',
    rentalManagementServiceListEight: 'Zusammenstellung von Gebäudeunterlagen',
    rentalManagementServiceListNine:
      'Führung der Objekt- und Finanzbuchhaltung inkl. Mahn- und Klagewesen',
    rentalManagementServiceListTen: 'Regelmäßige Begehung der Immobilie',
    rentalManagementServiceListEleven: 'Anweisung und Kontrolle von Servicekräften',
    rentalManagementServiceListTwelve: 'Überwachung des baulichen Zustandes',
    rentalManagementServiceListThirteen:
      'Vergabe und Überwachung von Reparatur-, Instandhaltungs- und Modernisierungsmaßnahmen',
    rentalManagementServiceListFourteen: 'Prüfung und Optimierung bestehender Verträge',
    rentalManagementServiceListFifteen:
      'Strategische Planung mit dem Ziel die Aufwands- und Ertragskomponenten zu optimieren',
    rentalManagementServiceListSixteen:
      'Planung von zukünftigen baulichen und gestalterischen Maßnahmen damit Sie entlastet werden',
    rentalManagementServiceListSeventeen:
      'Strategische Planung mit dem Ziel die Aufwands- und Ertragskomponenten zu optimieren',
    rentalManagementServiceListEighteen: 'Laufende Überwachung des baulichen Zustandes',
    rentalManagementServiceListNineteen: 'Regelmäßige Begehung der Immobilie',
    rentalManagementServiceListTwenty:
      'Anweisung und Kontrolle von Hilfskräften (Hausmeister, Putzfrau, Gärtner)',
    rentalManagementServiceListTwentyOne:
      'Vergabe und Überwachung von Reparaturen und Instandhaltungsmaßnahmen',
    rentalManagementServiceListTwentyTwo: 'Abschluss und Überprüfung von Wartungsverträgen',
    rentalManagementServiceListTwentyThree:
      'Planung von zukünftigen baulichen und gestalterischen Maßnahmen',
    adminMottto: 'Wir übernehmen Verantwortung damit Sie entlastet werden.',
    makeContact: 'Kontakt aufnehmen',
    doYouWantToSell: 'Sie möchten <span class="text-primary-500">verkaufen?</span>',
    sellDescriptionFirst:
      'Sie suchen nach einer realistischen Einschätzung Ihrer Immobilie? <br /> In diesem Fall bieten wir Ihnen kostenlos eine sachkundige Objektbewertung an.',
    sellDescriptionSecond:
      'Erfolgreicher Immobilien-Verkauf setzt fundierte Marktkenntnisse und ein hohes Maß an Erfahrung voraus. <br /> Wir von Beckschulte Immobilien-Management kennen den hiesigen Immobilienmarkt in all seinen Facetten und führen seit vielen Jahren Angebot und Nachfrage erfolgreich zusammen. ',
    sellDescriptionThird:
      'Mit uns haben Sie einen kompetenten Partner an Ihrer Seite, der den Verkauf Ihrer Immobilie professionell und engagiert betreibt. Sollten Sie erwägen jetzt oder in nächster Zeit Ihre Immobilie zu verkaufen, stehen wir Ihnen gerne für ein unverbindliches Gespräch zu Verfügung.',
    sellServices: 'Unser Leistungsumfang',
    sellServiceForYouFirst: 'Besichtigung und Erfassung Ihrer Immobilie',
    sellServiceForYouSecond: 'Erstellung einer sachverständigen Wertermittlung',
    sellServiceForYouThird: 'Kompetente und individuelle Beratung',
    sellServiceForYouFourth: 'Erstellung einer ansprechenden Fotoserie',
    sellServiceForYouFifth: 'Gestaltung eines aussagekräftigen Exposés',
    sellServiceForYouSixth: 'Präsentation auf diversen Internetportalen',
    sellServiceForYouSeventh: 'Einsatz von Verkaufsschildern, Flyern etc.',
    sellServiceForYouEight: 'Zusammenstellung der Verkaufsunterlagen',
    sellServiceForYouNine: 'Ansprache vorgemerkter Interessenten',
    sellServiceForYouTen: 'Versierte Führung bei Besichtigungen',
    sellServiceForYouEleven: 'Vorbereitung des Beurkundungstermins',
    sellServiceForYouTwelve: 'Begleitung der Parteien zum Notartermin',
    financeContact:
      'Nehmen Sie unverbindlich Kontakt zu uns auf <br /> … weil’s mit uns besser geht!',
    purchasePrice: 'Kaufpreis',
    rentPrice: 'Kaltmiete',
    housingCost: 'Hausgeld',
    viewThePrice: 'View the Price',
    expoetAsPdf: 'Kurzexpose als PDF',
    contactPerson: 'Ansprechpartner',
    readMore: 'weitere Infos',
    showLess: '..... weniger',
    imprintProfile: 'André Beckschulte',
    imprintCompanyName: 'André Beckschulte Immobilien-Management',
    imprintCompanyAddress: 'Friedrich-Ebert-Str 76 59425 Unna',
    imprintContactName: 'Ansprechpartner: André Beckschulte',
    becomePartner: 'Become a Partner',
    imprintTelNumber: '02303/94237-0',
    impruntFax: 'Fax: 02303/94237-209',
    imprintEmail: 'BIM@Beckschulte.com',
    imprintEmailDetail: 'USt.-Id-Nr.: 316/5017/1102',
    imprintCopyRight:
      'Erlaubnis nach § 34c Gewerbeordnung durch den Kreis Unna, Friedrich-Ebert-Str. 17, 59425 Unna.',
    imprintCertificate: 'Verbraucherinformation gemäß Verordnung (EU) Nr. 524/2013:',
    imprintContactOne:
      'Im Rahmen der Verordnung über Online-Streitbeilegung zu Verbraucherangelegenheiten steht Ihnen unter',
    imprintContactTwo: 'http://ec.europa.eu/customers/odr/',
    imprintContactThree: 'eine Online-Streitbeilegungsplattform der EU-Kommission zur Verfügung.',
    imprintContactEmailTitle: 'Kontakt per E-Mail:',
    imprintCertificateOne: 'Bildnachweis:',
    imprintCertificateTwo: 'Bildquellen von Bildern aus dem Archiv: www.fotolia.de',
    imprintCertificateThree:
      'Maridav - Fotolia.com, uview - Fotolia.com, Yuri Arcurs - Fotolia.com, aris sanjaya - Fotolia.com, Andrey Armyagov - Fotolia.com, Ilike - Fotolia.com, goodluz - Fotolia.com,photocreo - Fotolia.com , Monkey Business - Fotolia.com, Kzenon - Fotolia.com, pab_map - Fotolia.com',
    dataPrivacyTitle: 'Datenschutzerklärung',
    fieldIsRequired: 'Pflichtfeld',
    invalidEmail: 'In einer E-Mail-Adresse muss ein @-Zeichen enthalten.',
    salutation: 'Anrede',
    pleaseSelect: 'Bitte auswählen',
    firstName: 'Vorname',
    surnameCompany: 'Nachname/Firma',
    address: 'Strasse/Hausnummer',
    zipCode: 'PLZ/Ort',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    yourQuestion: 'Ihre Frage',
    canCallback: 'Ich bitte um einen Ruckruf.',
    copyToEmail: 'Ich wünsche eine Kopie der von mir eingegebenen Daten per E-Mail.',
    acceptTerm:
      'Hiermit erkläre ich mich einverstanden, dass meine eingegebenen Daten elektronisch gespeichert und zum Zweck der Kontaktaufnahme verarbeitet und genutzt werden. Mir ist bekannt, dass ich die Einwilligung jederzeit widerrufen kann. Datenschutzrichtlinien',
    send: 'abschicken',
    reset: 'zurücksetzen',
    contactSubTitle:
      'Wir möchten Ihre Anfrage gerne umgehend bearbeiten. Dazu benötigen wir einige Angaben von Ihnen. Das Ausfüllen der mit (*) gekennzeichneten Felder ist in jedem Fall erforderlich.',
    contactOpenClose: 'Öffnungszeit: Mo. - Fr. 08.00 -13.00 Uhr und 14.00 - 17.00 Uhr',
    contactAddress:
      'André Beckschulte Immobilien-Management - Friedrich-Ebert-Str. 76 - 59425 Unna.',
    contactPhone: '02303 / 94 237 - 0 ',
    contactEmail: 'BIM@Beckschulte.com',
    contactUs: 'Kontakt uns',
    imprintTelTitle: 'Tel',
    successful: 'Erfolgreich',
    failed: 'Fehlgeschlagen',
    needToBuy: 'Sie möchten verkaufen?',
    needToRent: 'Sie möchten vermieten?',
    ourTeam: '<span class="text-primary-500">Unser</span> Team',
    ourOffice: '<span class="text-primary-500">Unser</span> Büro',
    seeMore: 'weitere Infos',
    back: 'Zurück',
    buyerCommission: 'Käufer-Courtage',
    referenceObjects: 'Referenzobjekte',
    certificateMotto:
      'Durch unsere Mitgliedschaft in Berufsverbänden verpflichten wir uns zu <span class="text-primary-500">Qualität und hohen Leistungsstandards.</span>',
    homeAboutUsDescription:
      'Wir sind ein Team von Spezialisten und befassen uns seit mehr als 25 Jahren erfolgreich mit der Verwaltung, der Vermietung und dem Verkauf von Wohn- u. Gewerbe-Immobilien im Kreis Unna und dem gesamten Ruhrgebiet. Seriosität, Kompetenz und Engagement sind die Grundlagen unseres Handelns. Besonderen Wert legen wir auf die Qualifikation unserer Mitarbeiter, die über ausgezeichnete, fachspezifische Kenntnisse und Berufserfahrung verfügen. So erwartet Sie bei uns Professionalität verbunden mit Persönlichkeit.',
    learnMore: 'weitere Infos',
    description_hero_section:
      'Sie möchten eine Immobilie in <b>Menden</b> verkaufen, vermieten oder verwalten lassen? <b>Beckschulte Immobilien-Management</b> ist Ihr erfahrener <b>Immobilienmakler</b>. Mit über 25 Jahren Expertise begleiten wir Sie professionell – von der <b>Wertermittlung</b> über die <b>Vermarktung</b> bis hin zur sicheren <b>Vertragsabwicklung</b>. Vertrauen Sie auf unsere Marktkenntnis und persönliche Betreuung. <a href="https://beckschulte.com/kontakt" target="_blank" style="color: #3399ff;">Lassen Sie sich jetzt kostenlos beraten</a><b> – wir freuen uns auf Sie!</b>',
    description_hero_section_hamm:
      'Sie möchten Ihre <b>Immobilie in Hamm</b> verkaufen, vermieten oder verwalten lassen? Beckschulte Immobilien-Management ist Ihr verlässlicher Ansprechpartner für alle Fragen rund um Immobilien. Mit <b>mehr als 25 Jahren Erfahrung</b> bieten wir Ihnen einen umfassenden Service – von der fundierten Immobilienbewertung über eine maßgeschneiderte Vermarktung bis hin zur sicheren Vertragsabwicklung. Profitieren Sie von unserer lokalen Marktkenntnis und unserem individuellen Service. <a href="https://beckschulte.com/kontakt" target="_blank" style="color: #3399ff;">Jetzt unverbindlich beraten lassen!</b>',
    description_hero_section_iserlohn:
      'Sie möchten eine <b>Immobilie in Iserlohn</b> verkaufen, vermieten oder verwalten lassen? <b>Beckschulte Immobilien-Management</b> steht Ihnen mit Fachwissen und Erfahrung zur Seite. Wir begleiten Sie von der <b>realistischen Wertermittlung</b> über eine gezielte <b>Vermarktung</b> bis hin zur <b>sicheren Vertragsabwicklung</b>. Dank unserer <b>lokalen Marktkenntnis</b> und individuellen Betreuung erzielen wir für Sie das beste Ergebnis. <a href="https://beckschulte.com/kontakt" target="_blank" style="color: #3399ff;">Jetzt unverbindliche Beratung anfordern!</b>',
    contactTitle: 'Ihr Immobilienmakler in der Nähe – Schnell von Unna nach Menden',
    contactTitleHamm: 'Ihr Immobilienmakler in der Nähe – Schnell von Unna nach Hamm',
    contactDescription:
      '<b>Beckschulte Immobilien-Management</b> hat seinen Sitz in <b>Unna</b>, nur <b>30 Minuten</b> von Menden entfernt. Dank unserer regionalen Marktkenntnis sind wir Ihr idealer Partner für den <b>Immobilienverkauf, die Vermietung und Verwaltung</b> in Menden und Umgebung. Unsere Nähe ermöglicht persönliche Beratungstermine und eine schnelle Abwicklung Ihrer Immobilienanliegen.',
    contactDescriptionHamm:
      '<b>Beckschulte Immobilien-Management</b> hat seinen Sitz in <b>Unna</b>, nur <b>30 Minuten</b> von Hamm entfernt. Dank unserer <b>regionalen Marktkenntnis</b> und langjährigen Erfahrung sind wir Ihr zuverlässiger Partner für den <b>Immobilienverkauf, die Vermietung und Verwaltung</b> in Hamm und Umgebung. Unsere Nähe ermöglicht <b>persönliche Beratungstermine vor Ort</b> und eine schnelle, effiziente Abwicklung Ihrer Immobiliengeschäfte.',
    detailTitleMenden: 'Beckschulte ist Ihr Immobilienmakler in Menden',
    detailTitleHamm: 'Beckschulte – Ihr Immobilienmakler in Hamm',
    detailDescriptionMenden:
      '<ul class="list-disc lg:pl-2 front-normal"><li class="pl-1 pb-1"><b>Immobilienbewertung</b> – Fundierte Wertermittlung für Verkauf & Vermietung</li><li class="pl-1 pb-1"><b>Professionelle Vermarktung</b> – Hochwertige Exposés & optimale Platzierung</li><li class="pl-1 pb-1"><b>Regionale Marktkenntnis</b> – Spezialisierung auf Menden & das Ruhrgebiet</li><li class="pl-1 pb-1"><b>Langjährige Erfahrung</b> – Über 25 Jahre Expertise in der Immobilienbranche</li><li class="pl-1 pb-1"><b>Individuelle Betreuung</b> – Persönlicher Ansprechpartner für jede Immobilie</li><li class="pl-1 pb-1"><b>Sichere Vertragsabwicklung</b> – Begleitung von Verhandlungen bis zum Abschluss</li><li class="pl-1 pb-1"><b>Zertifizierter Immobilienmakler für Menden</b> – Mitglied in renommierten Immobilienverbänden</li><li class="pl-1 pb-1"><b>Professionelle Erstberatung</b> – Unverbindliche Beratung & Wertermittlung Ihrer Immobilie</li></ul>',
    detailDescriptionHamm:
      '<ul class="list-disc lg:pl-2 front-normal"><li class="pl-1 pb-1"><b>Immobilienbewertung</b> – Präzise Wertermittlung für Verkauf & Vermietung</li><li class="pl-1 pb-1"><b>Effektive Vermarktung</b> – Hochwertige Exposés & optimale Präsentation</li><li class="pl-1 pb-1"><b>Regionale Expertise</b> – Spezialisiert auf Hamm & das Ruhrgebiet</li><li class="pl-1 pb-1"><b>Langjährige Erfahrung</b> – Über <b>25 Jahre</b> in der Immobilienbranche</li><li class="pl-1 pb-1"><b>Persönliche Betreuung</b> – Ihr fester Ansprechpartner für alle Anliegen</li><li class="pl-1 pb-1"><b>Sichere Vertragsabwicklung</b> – Begleitung von Verhandlungen bis zum Abschluss</li><li class="pl-1 pb-1"><b>Geprüfter Immobilienmakler</b> – Mitglied in renommierten Verbänden</li><li class="pl-1 pb-1"><b>Unverbindliche Erstberatung</b> – Kostenlose Bewertung & Beratung Ihrer Immobilie</li></ul>',
    detailTitleIserlohn: 'Beckschulte – Ihr Immobilienexperte in Iserlohn',
    detailDescriptionIserlohn:
      '<ul class="list-disc lg:pl-2 front-normal"><li class="pl-1 pb-1"><b>Präzise Immobilienbewertung</b> – Marktgerechte Einschätzung für Verkauf & Vermietung</li><li class="pl-1 pb-1"><b>Gezielte Vermarktung</b> – Hochwertige Exposés und strategische Platzierung</li><li class="pl-1 pb-1"><b>Regionale Kompetenz</b> – Spezialisiert auf Iserlohn und das Umland</li><li class="pl-1 pb-1"><b>Mehr als 25 Jahre Erfahrung</b> – Fachkundige Unterstützung bei Immobiliengeschäften</li><li class="pl-1 pb-1"><b>Persönlicher Service</b> – Individuelle Betreuung durch einen festen Ansprechpartner</li><li class="pl-1 pb-1"><b>Rechtssichere Abwicklung</b> – Unterstützung von Verhandlung bis Vertragsabschluss</li><li class="pl-1 pb-1"><b>Zertifizierter Makler</b> – Mitglied anerkannter Immobilienverbände</li><li class="pl-1 pb-1"><b>Unverbindliche Erstberatung</b> – Unverbindliche Einschätzung Ihrer Immobilie</li></ul>',
    contactTitleIserlohn: 'Ihr Immobilienmakler in der Nähe – Schnell von Unna nach Iserlohn',
    contactDescriptionIserlohn:
      '<b>Beckschulte Immobilien-Management</b> hat seinen Sitz in <b>Unna</b>, nur <b>32 Minuten</b> von Iserlohn entfernt. Dank unserer <b>umfassenden Marktkenntnis</b> und langjährigen Erfahrung sind wir Ihr zuverlässiger Partner für den <b>Verkauf, die Vermietung und Verwaltung</b> von Immobilien in Iserlohn und Umgebung. Unsere Nähe ermöglicht <b>persönliche Beratungstermine vor Ort</b> und eine schnelle, effiziente Abwicklung Ihrer Immobiliengeschäfte.',
    faqTitleMenden: '',
    faqTitleHamm: 'Häufige Fragen zu Immobilienmaklern in Hamm',
    faqTitleIserlohn: 'Häufige Fragen zu Immobilienmaklern in Iserlohn',
    faqQuestionHamm1: 'Was kostet ein Immobilienmakler in Hamm?',
    faqAnswerHamm1:
      'Die Maklerprovision ist in Deutschland gesetzlich geregelt und wird in der Regel zwischen Käufer und Verkäufer aufgeteilt. Die Provision kann je nach individueller Vereinbarung variieren. Bei einer Vermietung ist die <b>Provision vom Vermieter zu tragen</b>, da das Bestellerprinzip gilt. Die Kosten lohnen sich jedoch, da ein Makler den bestmöglichen Preis erzielt und den gesamten Prozess professionell begleitet.',
    faqQuestionHamm2: 'Warum sollte ich einen Makler beauftragen?',
    faqAnswerHamm2:
      'Ein erfahrener Immobilienmakler übernimmt alle wichtigen Schritte im Verkaufs- oder Vermietungsprozess. Dazu gehören die <b>marktgerechte Wertermittlung</b>, eine professionelle <b>Vermarktungsstrategie</b>, die <b>Organisation von Besichtigungen</b> und die <b>rechtssichere Vertragsabwicklung</b>. Durch die Expertise eines Maklers erzielen Verkäufer oft einen <b>höheren Verkaufspreis</b>, vermeiden rechtliche Fallstricke und sparen wertvolle Zeit.',
    faqQuestionHamm3: 'Wie lange dauert es, eine Immobilie in Hamm zu verkaufen?',
    faqAnswerHamm3:
      'Die Dauer eines Immobilienverkaufs hängt von verschiedenen Faktoren wie <b>Lage, Preisgestaltung und Marktnachfrage</b> ab. In begehrten Lagen kann ein Verkauf bereits <b>innerhalb weniger Wochen</b> abgeschlossen sein. Durch eine <b>zielgerichtete Vermarktung</b>, die Nutzung professioneller Netzwerke und eine <b>realistische Preisbewertung</b> kann ein Makler den Verkaufsprozess erheblich beschleunigen.',
    faqQuestionIserlohn1: 'Wie läuft der Verkaufsprozess mit einem Immobilienmakler ab?',
    faqAnswerIserlohn1:
      'Ein Immobilienmakler begleitet Sie durch alle Phasen des Verkaufs. Zunächst wird eine <b>professionelle Wertermittlung</b> durchgeführt, gefolgt von der <b>Vermarktung auf relevanten Plattformen</b>. Nach der Koordination von Besichtigungsterminen übernimmt der Makler die <b>Preisverhandlungen</b> und begleitet Sie bis zum <b>notariellen Vertragsabschluss</b>. So stellen Sie sicher, dass Sie einen <b>optimalen Verkaufspreis erzielen</b> und der gesamte Prozess effizient und rechtssicher abläuft.',
    faqQuestionIserlohn2: 'Welche Unterlagen werden für den Immobilienverkauf benötigt?',
    faqAnswerIserlohn2:
      'Für einen reibungslosen Verkauf sollten wichtige Dokumente bereitliegen, darunter der <b>Grundbuchauszug</b>, ein aktueller <b>Energieausweis</b>, Baupläne sowie <b>Nachweise über Modernisierungen</b>. Ein Immobilienmakler hilft Ihnen, alle erforderlichen Unterlagen zu beschaffen. Fehlende Dokumente können den Verkaufsprozess verzögern – mit professioneller Unterstützung sind Sie bestens vorbereitet.',
    faqQuestionIserlohn3: 'Kann ich meine Immobilie auch ohne Makler verkaufen?',
    faqAnswerIserlohn3:
      'Ja, ein Verkauf ohne Makler ist möglich, jedoch oft mit <b>mehr Aufwand und Risiken</b> verbunden. Ein Makler übernimmt nicht nur die Wertermittlung und Vermarktung, sondern sorgt auch für <b>rechtssichere Verträge</b> und einen reibungslosen Ablauf, um Fehler und finanzielle Einbußen zu vermeiden. Gerade in einem dynamischen Markt kann eine falsche Preisstrategie zu <b>längeren Verkaufszeiten oder einem niedrigeren Erlös</b> führen.',
    faqQuestionIserlohn4: 'Wann ist der beste Zeitpunkt, eine Immobilie in Iserlohn zu verkaufen?',
    faqAnswerIserlohn4:
      'Der ideale Verkaufszeitpunkt hängt von <b>Marktentwicklung, Nachfrage und Zinssituation</b> ab. Ein Immobilienmakler kann Sie beraten, wann Ihre Immobilie <b>den besten Preis</b> erzielt und ob sich ein Verkauf aktuell lohnt. Markttrends ändern sich regelmäßig – eine professionelle Einschätzung hilft, <b>den richtigen Moment für den Verkauf nicht zu verpassen</b>.',
  },
};

export default de;
